<template>
  <div class="errPage-container">
    <el-button class="pan-back-btn" @click="back">
      <Icon icon="ic:baseline-arrow-back"/> Retourner
    </el-button>
    <el-row>
      <el-col :span="12">
        <h1 class="text-jumbo text-ginormous">Oops!</h1>
        <h2>vous n'avez pas la permission d'aller sur cette page</h2>
        <h6>
          Il se pourrait que vous ne soyez pas connecté. Pour avoir accès a votre compte
        </h6>
        <ul class="list-unstyled">
          <li>vous pouvez aller à :</li>
          <li class="link-type">
            <a href="https://opencontrat.com/connexion">Connexion</a>
          </li>
          <li class="link-type">
            <a href="https://opencontrat.com/inscription">Inscription</a>
          </li>
        </ul>
      </el-col>
      <el-col :span="12">
        <img
          :src="errGif"
          width="313"
          height="428"
          alt="Girl has dropped her ice cream."
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { Icon} from "@iconify/vue";
import errGif from "@/assets/401.gif";

export default {
  name: "Page401",
  data() {
    return {
      errGif: errGif + "?" + +new Date(),
    };
  },
  components: {
    Icon
  },
  methods: {
    back() {
      if (this.$route.query.noGoBack) {
        this.$router.push({ path: "/dashboard" });
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.errPage-container {
  width: 800px;
  max-width: 100%;
  margin: 100px auto;
  .pan-back-btn {
    background: #008489;
    color: #fff;
    border: none !important;
  }
  .pan-gif {
    margin: 0 auto;
    display: block;
  }
  .pan-img {
    display: block;
    margin: 0 auto;
    width: 100%;
  }
  .text-jumbo {
    font-size: 60px;
    font-weight: 700;
    color: #484848;
  }
  .list-unstyled {
    font-size: 14px;
    li {
      padding-bottom: 5px;
    }
    a {
      color: #008489;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
